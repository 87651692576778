import { DateRanges } from '../../__generated__/api-types-and-hooks'

export enum LABEL {
  EDIT = 'Edit',
  INDUSTRY = 'Industry',
  TOP_GOAL = 'Top Goal',
  CHALLENGES = 'Challenges',
  OTHER_GOALS = 'Other Goals',
  EDIT_ACCOUNT = 'Edit Account',
  BUSINESS_NAME = 'Business Name',
  BUSINESS_EMAIL = 'Business Email',
  BUSINESS_WEBSITE = 'Business Website',
  SUPPORT_HELP = 'Support & Help Areas',
  BUSINESS_STAGE = 'Business Stage',
  BUSINESS_VISION = 'Business Vision',
  YEARS_IN_BUSINESS = 'Years In Business',
  FOOD_INDUSTRY = 'Type of Food Industry',
  BUSINESS_AREA = 'Strong Business Areas',
  BUSINESS_LOCATION = 'Business Location',
  SERVICES_INDUSTRY = 'Type of Services Industry',
  BUSINESS_CLASSIFICATION = ' Business Classification',
  CREATION_BUSINESS_LOCATION = 'WHERE IS THE BUSINESS LOCATED',
  CREATION_BUSINESSCLASSIFICATION = 'HOW IS THE BUSINESS CLASSIFIED',
  REGISTER_BUSINESS_STRUCTURE = 'Registered Business Structure',
  MONTH = 'Month',
  YEAR = 'Year',
  PART_TIME_EMPLOYEES = 'Part-Time Employees',
  FULL_TIME_EMPLOYEES = 'Full-Time Employees',
  CONTRACT_EMPLOYESS = 'Contract Employees',
  VOLUNTEERS = 'Volunteers',
  EIN_NUMBER = 'EIN Number',
  NAICS_CODE = 'NAICS Code',
  TIME_COMMITMENT = 'Time Commitment',
  TOP_GOAL_MONTH = 'Top Goal for Next 6 Months',
  REGISTRATION_MONTH = 'Registration Month',
  MONTHLY_IMPACT_REPORT = 'Monthly Impact Statements',
}

export enum ANNOUNCEMENT_NAMES {
  NEW = 'New Announcement',
  CHOOSE = 'Choose Audience',
  SEND_TO_ALL_CLIENTS = 'Send to all clients',
  MAKE = 'Make an Announcement',
  SEND_TO_ALL_BO = 'All My Business Owners',
  PLACEHOLDER = 'Write a message to your clients here...',
  AFTER_PUBLISH = 'Published Response',
}

export enum ACCOUNT_TYPE {
  ADVISOR = 'Advisor',
  BUSINESS = 'Business',
}

export enum BUTTON_NAME {
  DONE = 'Done',
  PUBLISH = 'Publish',
  DASHBOARD = 'Dashboard',
}

export enum ANNOUNCEMENT_FIELD {
  SEND = 'sendTo',
  TEXT = 'text',
}

export enum INDUSTRY_TYPE {
  FOOD = 'Food',
  RETAIL = 'Retail',
  SERVICES = 'Services',
  ECOMMERCE = 'ECommerce',
  CHILDCARE = 'Childcare',
}

export enum FIELD_NAME {
  TOP_GOAL_OPTION_ID = 'topGoalOptionId',
  TOP_GOAL = 'topGoal',
  CHALLENGES = 'challenges',
  OTHER_GOALS = 'otherGoals',
  OTHER_GOALS_OPTION_IDS = 'otherGoalsOptionIds',
  BUSINESS_NAME = 'businessName',
  BUSINESS_STAGE_OPTION_ID = 'businessStageOptionId',
  BUSINESS_STAGE = 'businessStage',
  BUSINESS_VISION = 'businessVision',
  SUPPORT_AND_HELP_OPTION_IDS = 'supportAndHelpOptionIds',
  SUPPORT_AND_HELP = 'supportAndHelp',
  YEARS_IN_BUSINESS = 'yearsInBusiness',
  FOOD_SUB_INDUSTRY = 'foodSubIndustry',
  FOOD_SUB_INDUSTRY_OPTION_IDS = 'foodSubIndustryOptionIds',
  BUSINESS_LOCATIONS_OPTION_IDS = 'businessLocationsOptionIds',
  BUSINESS_LOCATIONS = 'businessLocations',
  SERVICES_SUB_INDUSTRY = 'servicesSubIndustry',
  SERVICES_SUB_INDUSTRY_OPTION_ID = 'servicesSubIndustryOptionId',
  STRONG_BUSINESS_AREAS_OPTION_IDS = 'strongBusinessAreasOptionIds',
  STRONG_BUSINESS_AREAS = 'strongBusinessAreas',
  BUSINESS_CLASSIFICATION_OPTION_ID = 'businessClassificationOptionId',
  BUSINESS_CLASSIFICATION = 'businessClassification',
  REGISTER_BUSINESS_STRUCTURE = 'registerBusinessStructure',
  REGISTER_BUSINESS_STRUCTURE_OPTION_ID = 'registerBusinessStructureOptionId',
  BUSINESS_EMAIL = 'businessEmail',
  DOMAIN_NAME = 'domainName',
  MONTH = 'month',
  YEAR = 'year',
  PART_TIME_EMPLOYEES = 'partTimeEmployees',
  FULL_TIME_EMPLOYEES = 'fullTimeEmployees',
  CONTRACTORS = 'contractors',
  VOLUNTEERS = 'volunteers',
  EIN_NUMBER = 'EIN',
  NAICS_CODE = 'naicsCode',
  TIME_COMMITMENT = 'businessNature',
  TIME_COMMITMENT_OPTION_ID = 'businessNatureOptionId',
}

export enum BUSINESS_LOCATIONS {
  RETAIL_FOOD = 'retailAndFoodBusinessLocation',
  SERVICES = 'servicesBusinessLocation',
  ECOMMERCE = 'eCommerceBusinessLocation',
  CHILDCARE = 'childCareBusinessLocation',
}

export enum FORM {
  USER_PROFILE = 'USER_PROFILE',
  PROFILE_FORM = 'PROFILE_FORM',
  ADVISOR_PROFILE_FORM = 'ADVISOR_PROFILE_FORM',
  BUSINESS_PROFILE = 'BUSINESS_PROFILE',
  INITIAL_ASSESSMENT = 'INITIAL_ASSESSMENT',
  BUSINESS_ASSESSMENT_FORM = 'BUSINESS_ASSESSMENT_FORM',
  ADVISOR_FORM = 'ADVISOR_FORM',
  ADMIN_ACCESS = 'ADMIN_ACCESS',
  USER_PROFILE_FORM = 'USER_PROFILE_FORM',
  OWNERS = 'OWNERS',
  TOPGOAL = 'TOPGOAL',
  BUSINESS_OTHER_DETAILS = 'BUSINESS_OTHER_DETAILS',
  USER_MANAGEMENT_BUSINESS_FORM = 'USER_MANAGEMENT_BUSINESS_FORM',
  OTHER_GOALS = 'OTHER_GOALS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  CALENDAR = 'CALENDAR',
  USER_AVAILABILITY = 'USER_AVAILABILITY',
  PLAY_FORM = 'PLAY_FORM',
  MONTHLY_ATTESTATION = 'MONTHLY_ATTESTATION',
  MEETING_SCHEDULED = ' MEETING_SCHEDULED',
  SMS_ENABLED = 'SMS_ENABLED',
  ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
  BUSINESS_PROFILE_FIELDS = 'BUSINESS_PROFILE_FIELDS',
  ANNOUNCEMENT_FORM = 'ANNOUNCEMENT_FORM',
  BUSINESSES_PROFILE = 'BUSINESSES_PROFILE',
  USER_MANAGEMENT_FORM = 'USER_MANAGEMENT_FORM',
  CLIENT_BUSINESS_PROFILE = 'CLIENT_BUSINESS_PROFILE',
  USER_ACCOUNTS_BUSINESS_FORM = 'USER_ACCOUNTS_BUSINESS_FORM',
}

export enum ROLES {
  BSO_ADMIN = 'admin',
  BUSINESS_OWNER = 'owner',
  BSO_ADVISOR = 'advisor',
  APP_ADMIN = 'app-admin',
  V2 = 'owner-v2',
  V2_ADVISOR = 'advisor-v2',
  V2_ADMIN = 'admin-v2',
}

export enum CREATE_BUSINESS_ACCOUNT_STEPS {
  PROFILE_FORM = 'Profile Form',
  ADDITIONAL_DETAILS = 'Additional Details',
  ASSIGN_AN_ADVISOR = 'Assign Advisor',
  CREATE_ACCOUNT = 'Create Account',
  ACCOUNT_CREATED = 'Account Created',
}

export enum CREATE_ADVISOR_ACCOUNT_STEPS {
  PROFILE_FORM = 'Profile Form',
  ASSIGN_A_CLIENT = 'Assign a Client',
  ADMIN_ACCESS = 'Admin Access',
  CREATE_ACCOUNT = 'Create Account',
  ACCOUNT_CREATED = 'Account Created',
}

export enum ERROR_MESSAGE_MAPPING {
  'An account with the given email already exists' = 'An account with this email already exists',
}

export enum GOAL_ACTION {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TOP_GOAL = 'topGoal',
}

export const ASSESSMENT_USER_PRIVACY = {
  admin: 'showToAdmin',
  owner: 'showToOwner',
}

export enum CYCLR_CALENDAR {
  GOOGLE_CALENDAR = 'Google Calendar',
  SQUARE_UP = 'Squareup',
  CALENDLY = 'Calendly',
  MICROSOFT_OFFICE_365 = 'Microsoft Office 365',
  QUICKBOOKS = 'QuickBooks',
}

export enum CYCLR_OAUTH {
  SQUARE_UP = 'Squareup',
  QUICKBOOKS = 'QuickBooks',
}
export enum CYCLR_CONNECTORS {
  SQUARE_UP = 'Squareup',
  CALENDLY = 'Calendly connector',
  GOOGLE_CALENDAR = 'Google connector',
  MICROSOFT_OFFICE_365 = 'Microsoft Office 365',
  QUICKBOOKS = 'QuickBooks',
}

export enum CYCLR_CONNECTOR_ID {
  SQUARE_UP = 19596,
  CALENDLY = 19634,
  GOOGLE_CALENDAR = 19546,
  MICROSOFT_OFFICE_365 = 19555,
  QUICKBOOKS = 19740,
}

export const PLAY_STEPS = {
  GUIDE: 'guide',
  SETUP: 'setup',
  USE: 'use',
}

export const PLAY_CARDS = {
  COLLAPSEABLE: 'collapseable',
}

export const PLAY = {
  MOVE_NEXT_BUTTON: 'next',
  INTEGRATION: 'connect',
  SUGGESTIONS: 'optional-plays',
  CALENDAR_MEETING: 'calandar-meeting-scheduling',
  COMPLETE_BUTTON: 'play-complete-button',
}

export const PLAY_CARD_BODY_DATA = {
  HTML: 'html',
  QUESTION: 'question',
}

export enum FILTER_TYPE {
  THIS_TIME = 'all time',
  THIS_WEEK = 'this week',
  THIS_YEAR = 'this year',
  THIS_MONTH = 'this month',
}

export enum USER_MANAGEMENT_TABS {
  LABEL_BUSINESSES = 'BUSINESSES',
  LINK_BUSINESSES = 'businesses',
  LABEL_ADVISERS = 'ADVISERS',
  LINK_ADVISORS = 'advisers',
}

export enum USER_MANAGEMENT_FILTER {
  STATUS = 'status',
  ADVISOR = 'advisor',
  SEARCH_QUERY = 'searchQuery',
}

export enum USER_ROLES {
  BSO_ADMIN = 'BSO_ADMIN',
  BSO_ADVISOR = 'BSO_ADVISOR',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
}

export enum BIG_QUERY {
  BOTTOM_5_CUSTOMERS = 'BOTTOM_5_CUSTOMERS',
}

export enum USER_STATUS {
  NA = 'NA',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  INVITED = 'invited',
  DISABLED = 'disabled',
  ENABLE_USER = 'Enable User',
  DISABLE_USER = 'Disable User',
}

export enum LOG_STATUS {
  COMPLETED = 'Completed',
  STARTED = 'Started',
}

export enum PLAY_INTEGRATION_QUERY_PARAMS {
  SUCCESS = '?success=true',
}

export enum PLAY_TYPE {
  TOOL = 'Existing Tool - Usage',
  CONNECT = 'Recommend Tool - Account Set up & Connect',
  EDUCATION = 'Education',
  PROCESS = 'Process',
}

export enum UPLOAD_TYPE {
  IMAGE = 'image',
  DOCUMENT = 'doc',
}

export enum STATUS {
  LOADING = 'loading',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  NONE = 'none',
}
export enum MEETING_STEPPER {
  ADVISOR = 'Schedule Meeting',
  CALENDAR = 'Select a Date',
  SLOTS = 'Select a Time',
  DESCRIPTION = 'Confirm Meeting',
  MEETING_CONFIRMATION = 'Meeting confirmation',
  MEETING_WARNING = 'Meeting warning',
}

export enum MEETING_LOCATION_LABEL {
  MS_TEAMS = 'Microsoft Teams',
  GOOGLE_MEET = 'Google Meet',
  ZOOM = 'Zoom',
  CUSTOM = 'In-person/Phone call/Custom link',
}

export enum MEETING_LOCATION_PROVIDER {
  MS_OFFICE_365 = 'Microsoft Outlook',
  GOOGLE_CALENDAR = 'Google Calendar',
  ZOOM = 'Zoom',
}

export enum TIME_SLOT {
  START_TIME = '12:00 AM',
  END_TIME = '12:00 AM',
  DURATION = 30,
}
export enum LOG_ACTIVITY_ACTION {
  SIGN_IN = 'signin',
  SIGN_OUT = 'signout',
}

export enum PLAY_ACTIONS {
  MEETING_SCHEDULED = 'Meeting Scheduled',
}
export enum REGISTER_BUSINESS {
  TELL_ME_MORE = 'I don’t know/Tell me more',
  DONT_HAVE_YET = 'I don’t have one yet',
}
export enum MEETING_LABEL {
  CANCEL_MEETING = 'CANCEL MEETING',
  SAVE = 'Save',
  CREATE_MEETING = 'Create meeting',
  CANCEL = 'Cancel',
  LOCATION = 'Location',
  GUESTS = 'Guests',
  EDIT = 'Edit',
}

export enum ADVISOR_ONBOARDING {
  ADVISOR_ONBOARD = 'advisor onboarding',
}
export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum FILTER_OPTIONS {
  ALL = 'All',
  NOT_ASSIGNED = 'Not Assigned',
}
export enum OPTION_TYPE {
  SUCCESS_DESCRIPTION = 'successDescription',
  MULTI_FIELD = 'multi-field',
  INPUT_NUMERIC_SLOT = 'input-numeric-slots',
  CURRENCY = 'currency',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  INPUT = 'input',
  UPLOAD = 'upload',
}
export enum ASSESSMENT_TYPE {
  INITIAL = 'initial',
  BASELINE = 'baseline_assessment',
  BUSINESS_ESSENTIALS = 'business_essentials',
  FINANCE_ESSENTIALS = 'finance_lvl',
  SALES_ESSENTIALS = 'sales_lvl',
  MARKETING_ESSENTIALS = 'marketing_lvl',
}
export enum INPUT_FIELD_OPTION_TYPE {
  INPUT_NUMERIC_SLOT = 'input-numeric-slots',
  INPUT = 'input',
}
export enum VALUE_TYPE {
  STRING = 'string',
}

export const DateRangesGrowthLabellings = {
  [DateRanges.ThisMonth]: 'MoM',
  [DateRanges.ThisQuarter]: 'QoQ',
  [DateRanges.Last12Months]: 'YoY',
  [DateRanges.ThisYearToDate]: 'YoY',
}

export const DateRangesNamesMap = {
  [DateRanges.ThisMonth]: 'This Month',
  [DateRanges.ThisQuarter]: 'This Quarter',
  [DateRanges.Last12Months]: 'Last 12 Months',
  [DateRanges.ThisYearToDate]: 'This Year To Date',
}
export enum FILE_ACTION {
  DOWNLOAD = 'download',
  VIEW = 'view',
}
export enum RECOMMENDATION_CARD_TYPE {
  PLAY = 'play',
  ASSESSMENT = 'assessment',
}
export enum CURRENT_TAB {
  DASHBOARD = 'dashboard',
  PLAYBOOK = 'playbooks',
  ASSESSMENT = 'assessment',
}
export enum PLAY_ASSESSMENT_STATUS {
  COMPLETED = 'completed',
}
export enum FIND_VALUE {
  BUSINESS_STAGE = 'businessStage',
  BUSINESS_CLASSIFICATION = 'businessClassification',
}
export enum PLAYBOOK_ASSESSMENTS {
  SALES_ESSENTIALS = 'sales_lvl',
  FINANCING_ESSENTIALS = 'finance_lvl',
  MARKETING_ESSENTIALS = 'marketing_lvl',
  BUSINESS_ESSENTIALS = 'business_essentials',
}
export enum ONBOARDING {
  WELCOME_ADVISOR = 'Welcome Advisor',
  SMS_SUBSCRIPTION = 'Sms Subscription',
  SMS_UNSUBSCRIBE_CONFIRMATION = 'Sms Unsubscribe',
  AVAILABILITY_INFO = 'Availability Info',
  SET_AVAILABILITY = 'Set Availability',
}
export enum ACTIVE_STEP {
  WELCOME_OWNER = 'Welcome Owner',
  CREATE_PROFILE = 'Create Profile',
  SMS_SUBSCRIPTION = 'Sms Subscription',
  QUESTIONS = 'Questions',
  SMS_UNSUBSCRIBE_CONFIRMATION = 'Sms Unsubscribe',
}

export enum ERROR_MESSAGE_TYPES {
  VALIDATION_ERROR_MESSAGE = 'validationErrorMessage',
  LICENSE_ERROR_MESSAGE = 'licenseErrorMessage',
  ASSESSMENT_ERROR_MESSAGE = 'assessmentErrorMessage',
}

export enum TOOL_FLYOUT_OPTIONS {
  LEARN_MORE = 'learnMore',
  PLAYS = 'plays',
  DIAGNOSTICS = 'diagnostics',
  HELP = 'help',
}
export enum Status {
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  INREVIEW = 'inReview',
  BACKLOG = 'backlog',
}
