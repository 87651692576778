import React, { FC, useEffect } from 'react'
import { ConnectedProps, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { GoalCenterConnector } from './GoalCenter.connector'
import EcommerceIcon from 'components/Common/SvgIcons/EcommerceIcon'
import Button from 'components/Common/Button'
import { GoalCenterHeading, GoalCenterSubHeading, GoalCenterWrapper } from './GoalCenter.style'
import { getTenantId } from 'utils/getTenantId'
import history from 'utils/history'
import {
  useGetBusinessGoalsQuery,
  UserTaskItemStatus,
  useUpdateBusinessGoalMutation,
} from '__generated__/api-types-and-hooks'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { STATUS } from './data'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'
import { RootState } from 'App'

interface IGoalCenterProps extends ConnectedProps<typeof GoalCenterConnector> {}

const GoalCenter = GoalCenterConnector<FC<IGoalCenterProps>>(({ userId }) => {
  const tenantId = getTenantId()
  const { clientId } = useParams()
  console.log('clientId ', clientId)
  // const userId = useSelector((state:RootState) =>state.user.user.id)
  const { data, isLoading, refetch } = useGetBusinessGoalsQuery({
    input: { userId: clientId ? clientId : userId, tenantId },
  })
  return (
    <GoalCenterWrapper>
      {getRoleFromPath()?.includes(ROLES.BUSINESS_OWNER) && (
        <Button
          label="Go to Dashboard"
          variant="primary"
          onClick={() => history.push(`/owner/${tenantId}/dashboard`)}
        />
      )}
      <div>
        <GoalCenterHeading className="p-4"> Welcome to your Goal Center! </GoalCenterHeading>
        <GoalCenterSubHeading className="px-4">
          Below are all your goals and you can click on each to see more.
        </GoalCenterSubHeading>
      </div>
      <div className="container mx-auto p-3">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <GradientCircleNotchIcon width="100" height="100" />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
            {data?.getBusinessGoals &&
              data?.getBusinessGoals.map((goal) => (
                <Card
                  key={goal.goalId}
                  title={goal.name}
                  progress={goal.progress!}
                  goalId={goal.goalId}
                  status={goal.status!}
                  refetchGoal={refetch}
                />
              ))}
          </div>
        )}
      </div>
    </GoalCenterWrapper>
  )
})

export default GoalCenter

interface ICardProps {
  title: string
  progress: number
  goalId: string
  status: string
  refetchGoal: () => void
}

const Card = ({ title, progress, goalId, status, refetchGoal }: ICardProps) => {
  const tenantId = getTenantId()
  const role = getRoleFromPath()
  const { mutate, isLoading } = useUpdateBusinessGoalMutation()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const userId = useSelector((state: RootState) => state.user.user.id)
  useEffect(() => {
    if (!isLoading) refetchGoal()
    // eslint-disable-next-line
  }, [isLoading])
  return (
    <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center">
      <EcommerceIcon className=""></EcommerceIcon>
      <>
        <h2 className="my-1 text-lg font-bold text-center">{title}</h2>
        <ProgressBar progress={progress}></ProgressBar>
        <Button
          label={STATUS[status]}
          variant={progress === 100 ? 'secondary' : 'primary'}
          className="!py-1 my-3 text-black"
          disabled={STATUS[status] === STATUS.PAUSED}
        />
        {STATUS[status] !== UserTaskItemStatus.Paused ? (
          <Button
            label="Pause Goal"
            variant="text"
            className="!py-1 my-3 text-black"
            onClick={() => {
              mutate({
                input: {
                  goalId: goalId,
                  tenantId: tenantId,
                  status: UserTaskItemStatus.Paused,
                  userId,
                },
              })
            }}
          />
        ) : (
          <Button
            label="UnPause Goal"
            variant="text"
            className="!py-1 my-3 text-black"
            onClick={() => {
              mutate({
                input: {
                  goalId: goalId,
                  tenantId: tenantId,
                  userId,
                },
              })
            }}
          />
        )}
        <Button
          label="Plan detail"
          variant="text"
          onClick={() =>
            role?.includes(ROLES.BUSINESS_OWNER)
              ? history.push(`/${role}/${tenantId}/goals/${goalId}/planSummary`)
              : history.push(`/${role}/${tenantId}/clients/${clientId}/goals/${goalId}/planSummary`)
          }
          disabled={STATUS[status] === STATUS.PAUSED}
        />
      </>
    </div>
  )
}

interface IProgressBarProps {
  progress: number
}

const ProgressBar = ({ progress }: IProgressBarProps) => {
  return (
    <div className="h-6 flex-1 flex justify-end xs:w-[100%] xs:mt-2 sm:mt-[0px]">
      <div className="w-full min-h-full flex items-center justify-center space-y-8">
        <div className="w-full bg-cream-dark rounded-[21px] overflow-hidden">
          <div
            className="bg-gradient-to-r from-[#52B4F9] to-[#8400F7] rounded-[21px] h-5"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  )
}
