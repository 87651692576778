import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Columns } from 'types'
import { isEmpty } from 'lodash'
import { RootState } from 'App'
import { useSelector } from 'react-redux'
import { ACCOUNT_TYPE, FILTER_OPTIONS, ROLES, statusOptions } from 'config'

import Button from 'components/Common/Button'
import SortButton from 'components/Common/SortButton'
import InputField from 'components/Common/InputField'
import { SearchIcon } from 'components/Common/SvgIcons'
import SelectField from 'components/Common/SelectField'
import FilterButton from 'components/Common/FilterButton'
import RadioButtonField from 'components/Common/RadioButtonField'
import Details from 'components/Admin/UserManagement/Details/index'
import { UserCountWrapper } from 'components/Admin/UserManagement/style'
import FilterSettingsMenu from 'components/Common/FilterSettingsModal'
import UserManagementList from 'components/Admin/UserManagement/UserManagementList/index'

import { BSOState } from 'store/reducers/bso'
import { navigateTo, getRoleFromPath, sortHandlerHelper, replaceTerm } from 'utils/helper'
import { SortOrder } from '__generated__/api-types-and-hooks'
import DataTableV2 from 'components/Common/DataTableV2'
// import DataTable from 'components/Common/DataTable'
import { IFilter } from '../config/interfaces'
import { defaultPaginationValues, noOfItemsPerPageOptions } from '../config/data'

export const STATUS_SELECT_TEST_ID = 'STATUS_SELECT_TEST_ID'
export const ADVISOR_SELECT_TEST_ID = 'ADVISOR_SELECT_TEST_ID'

interface IUserManagementLayoutProps {
  /** sortFields represent userAccount properties that can be used to sort userAccounts in the UI */
  sortFields: Array<{ label: string; value: string; fieldType?: string }>
  /** Properties on a userAccount to be used by the search bar to find matches */
  searchByProperties: string[] // todo - type these properties like "keyof UserAccount" when a common "UserAccount" interface exists
  showAdvisorFilter?: boolean
  userAccountType: 'Business' | 'Advisor'
  searchInputPlaceholder: string
  /** The URL path pointing to the page on which new users are created. */
  createAccountUrlPath: string
  /** Columns that determine how user account data is displayed in the table. See "Advisors" or "Businesses" component for examples. */
  tableColumns: Columns
  /** The user accounts that populate the table and/or "UserManagementList" */
  userAccounts: any[] // todo - create a common interface for the various user accounts based on needed attributes in this component
  loading: boolean
  /** A callback intended to wrap whatever redux dispatch actions needed to fetch & store whatever data when the page loads. */
  getData: () => void
  bso: BSOState
  tenantId: string
  advisors: { label: string; value: string }[]
  isUserAddedToGroup: boolean
  isDisableUserInProgress: boolean
  setCurrentPageAction: (value: number) => void
  setPerPageAction: (value: number) => void
  setFiltersAction: (filters: IFilter[]) => void
  currentPage: number
  noOfItemsPerPage: number
  totalPages: number
  filters: IFilter[]
  sortValue: string
  setSortValueAction: (sortValue: string) => void
  isV2FlagOn: boolean
}

const UserManagementLayout: FC<IUserManagementLayoutProps> = ({
  bso,
  loading,
  tenantId,
  isV2FlagOn,
  getData,
  advisors,
  sortFields,
  userAccounts,
  tableColumns,
  userAccountType,
  searchByProperties,
  createAccountUrlPath,
  searchInputPlaceholder,
  showAdvisorFilter = false,
  isUserAddedToGroup,
  isDisableUserInProgress,
  setCurrentPageAction,
  setPerPageAction,
  setFiltersAction,
  currentPage,
  noOfItemsPerPage,
  totalPages,
  filters,
  sortValue,
  setSortValueAction,
}) => {
  // const [pageSize, setPageSize] = useState(20)

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bso, currentPage, noOfItemsPerPage, filters, sortValue])
  const [displayedUserAccounts, setDisplayedUserAccounts] = useState(userAccounts)
  const [haveData, setHaveData] = useState(true)

  const userRole = getRoleFromPath()
  const createNewUserAccount = useCallback(() => {
    navigateTo(isV2FlagOn ? ROLES.V2_ADMIN : ROLES.BSO_ADMIN, createAccountUrlPath)
    // eslint-disable-next-line
  }, [userRole, createAccountUrlPath, isV2FlagOn])

  const [searchbarText, setSearchbarText] = useState<string | undefined>()

  const [displayedFilterSettingsMenu, setDisplayedFilterSettingsMenu] = useState<
    'sort' | 'filter' | null
  >(null)

  const onSortButtonClicked = () => {
    setDisplayedFilterSettingsMenu('sort')
  }

  const onFilterButtonClicked = () => {
    setDisplayedFilterSettingsMenu('filter')
  }

  const filterSettingsMenuTitle = useMemo(() => {
    return displayedFilterSettingsMenu || ''
  }, [displayedFilterSettingsMenu])

  /** The property on a business ("name", "businessName", etc.) currently being used to sort the displayed data */
  const [sortField, setSortField] = useState(sortFields[0].value)

  /** The field that will sort user accounts once the "Apply" button is clicked. (small screens only) */
  const [pendingSortField, setPendingSortField] = useState(sortFields[0].value)

  const fieldType = useMemo(() => {
    return sortFields.find((sf) => sf.value === sortField)!?.fieldType || ''
  }, [sortField, sortFields])

  /** The currently filtered advisor. If set, only user accounts associated with this advisor appear in displayed data. */
  const [advisorFilter, setAdvisorFilter] = useState('')

  /** Becomes the advisor filter after user clicks "Apply" (small screens only) */
  const [pendingAdvisorFilter, setPendingAdvisorFilter] = useState('')

  /** The currently filtered status. If set, only user accounts with this status appear in displayed data. */
  const [statusFilter, setStatusFilter] = useState('')

  /** Becomes the status filter after user clicks "Apply" (small screens only) */
  const [pendingStatusFilter, setPendingStatusFilter] = useState('')

  const restoreDefaultSortAndFilters = () => {
    const advisor = ''
    const status = ''
    const sort = sortFields[0].value

    setSortField(sort)
    setPendingSortField(sort)
    setPendingAdvisorFilter(advisor)
    setAdvisorFilter(advisor)
    setPendingStatusFilter(status)
    setStatusFilter(status)
    setSearchbarText('')
    setDisplayedFilterSettingsMenu(null)
    executeSearch(defaultPaginationValues.search)
  }

  const closeFilterModalAndRevertPendingChanges = () => {
    if (displayedFilterSettingsMenu === 'sort') {
      setPendingSortField(sortField)
    } else if (displayedFilterSettingsMenu === 'filter') {
      setPendingAdvisorFilter(advisorFilter)
      setPendingStatusFilter(statusFilter)
    }
    setDisplayedFilterSettingsMenu(null)
  }

  /** Apply a set of pending changes to filters and update the displayed data */
  const applyPendingFilterChanges = () => {
    /* Use the "pending" filter values as the new filters... */
    setAdvisorFilter(pendingAdvisorFilter)
    setStatusFilter(pendingStatusFilter)

    const newFilter = filters.filter((item) => {
      if (
        item.type === defaultPaginationValues.filterTypes.status ||
        item.type === defaultPaginationValues.filterTypes.advisor
      )
        return false
      return true
    })

    const statusFilterValues: string[] = []
    const advisorFilterValues: string[] = []

    if (pendingStatusFilter && pendingStatusFilter !== defaultPaginationValues.search) {
      statusFilterValues.push(pendingStatusFilter)
    }

    if (pendingAdvisorFilter && pendingAdvisorFilter !== defaultPaginationValues.search) {
      advisorFilterValues.push(pendingAdvisorFilter)
    }

    setFiltersAction([
      ...newFilter,
      { type: defaultPaginationValues.filterTypes.status, values: statusFilterValues },
      { type: defaultPaginationValues.filterTypes.advisor, values: advisorFilterValues },
    ])
    // sortAndFilterUserAccounts()
  }

  /** Immediately apply a status as a filter and recompute displayed user accounts (Does not require confirmation with "Apply" button click) */
  const applyStatusFilter = (status) => {
    setPendingStatusFilter(status)
    setStatusFilter(status)
    const newFilter = filters.filter(
      (item) => item.type !== defaultPaginationValues.filterTypes.status
    )
    setFiltersAction([
      ...newFilter,
      {
        type: defaultPaginationValues.filterTypes.status,
        values: status === defaultPaginationValues.search ? [] : [status],
      },
    ])
    setCurrentPageAction(defaultPaginationValues.currentPage)
    // sortAndFilterUserAccounts()
  }

  /** Immediately apply an advisor as a filter and recompute displayed user accounts (Does not require confirmation with "Apply" button click) */
  const applyAdvisorFilter = (advisor) => {
    setPendingAdvisorFilter(advisor)
    setAdvisorFilter(advisor)
    const newFilter = filters.filter(
      (item) => item.type !== defaultPaginationValues.filterTypes.advisor
    )
    setFiltersAction([
      ...newFilter,
      {
        type: defaultPaginationValues.filterTypes.advisor,
        values: advisor === defaultPaginationValues.search ? [] : [advisor],
      },
    ])
    setCurrentPageAction(1)
    // sortAndFilterUserAccounts()
  }

  const applySortSettings = (field = pendingSortField) => {
    setSortField(field)
    // sortAndFilterUserAccounts()
  }

  /** This is currently just another filter */
  const handleSearch = (e) => {
    const text = e.target.value
    setSearchbarText(text)
    if (!text) {
      executeSearch(defaultPaginationValues.search)
    }
    // sortAndFilterUserAccounts()
  }

  const executeSearch = (text: string) => {
    if (searchbarText) {
      setFiltersAction([
        { type: defaultPaginationValues.filterTypes.search, values: [searchbarText] },
      ])
    }

    // check to see if search text is empty then reset
    if (text === defaultPaginationValues.search) {
      setFiltersAction([{ type: defaultPaginationValues.filterTypes.search, values: [''] }])
    }

    setCurrentPageAction(1)
    setPendingStatusFilter(FILTER_OPTIONS.ALL.toLocaleLowerCase())
    setStatusFilter(FILTER_OPTIONS.ALL.toLocaleLowerCase())
    setPendingAdvisorFilter(FILTER_OPTIONS.ALL.toLocaleLowerCase())
    setAdvisorFilter(FILTER_OPTIONS.ALL.toLocaleLowerCase())
  }

  /** Sort a provided array of user accounts using the current sortField */
  const sort = useCallback(
    (data) => {
      const results = sortHandlerHelper({
        sortOrder: SortOrder.Ascending,
        fieldType: fieldType,
        sortField: sortField,
        data: data,
      })
      return results
    },
    [fieldType, sortField]
  )

  /** Applies filtering and sorting to user accounts */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortAndFilterUserAccounts = useCallback(() => {
    if (loading) return
    const normalizeText = (val: string) => val?.toLowerCase().replace(/\s/g, '')

    let _userAccounts =
      userAccounts &&
      userAccounts.filter((userAccount) => {
        if (showAdvisorFilter) {
          if (
            (advisorFilter &&
              advisorFilter !== FILTER_OPTIONS.ALL.toLowerCase() &&
              advisorFilter !== FILTER_OPTIONS.NOT_ASSIGNED.toLowerCase() &&
              !userAccount?.advisors?.includes(advisorFilter)) ||
            (advisorFilter === FILTER_OPTIONS.NOT_ASSIGNED.toLowerCase() &&
              userAccount?.advisors !== '')
          )
            return false
        }
        if (statusFilter && statusFilter !== 'all' && userAccount?.status !== statusFilter) {
          return false
        }
        if (searchbarText) {
          const searchChars = normalizeText(searchbarText)

          const matchesSearchText = searchByProperties.some((prop) => {
            const text = userAccount?.[prop]
            if (!text || typeof text !== 'string') return false
            return normalizeText(text)?.includes(searchChars)
          })

          if (!matchesSearchText) return false
        }
        return true
      })

    isEmpty(_userAccounts) && setHaveData(false)
    _userAccounts = sort(_userAccounts)
    setDisplayedUserAccounts(_userAccounts)
    // eslint-disable-next-line
  }, [
    userAccounts,
    sort,
    showAdvisorFilter,
    statusFilter,
    searchbarText,
    advisorFilter,
    searchByProperties,
  ])

  /** Automatically updates displayed data if "userAccounts" (underlying redux data) changes */
  useEffect(() => {
    // sortAndFilterUserAccounts()
    setDisplayedUserAccounts(userAccounts)
  }, [userAccounts])

  /** Closes the sort/filter menu and applies the pending changes. The associated button only appears in a modal on small screens. */
  const onAppliedClicked = () => {
    const menu = displayedFilterSettingsMenu
    if (!menu) return
    if (menu === 'sort') applySortSettings()
    if (menu === 'filter') applyPendingFilterChanges()
    setDisplayedFilterSettingsMenu(null)
  }

  // replace keywords
  const advisorTerm = replaceTerm(userAccountType, bso?.bsoProfile?.terminology)

  const moveNextHandler = (value: number) => {
    setCurrentPageAction(value)
  }

  const movePreviousHandler = (value: number) => {
    setCurrentPageAction(value)
  }

  const updateNoOfItemPerPageHandler = (value: string) => {
    const updatedValue = Number(value)
    setCurrentPageAction(1)
    setPerPageAction(updatedValue)
  }

  const apiDataSortHandler = (sortValue: string) => {
    setSortValueAction(sortValue)
  }

  return (
    <div>
      <div className="w-full overflow-auto mdl:overflow-hidden space-y-5">
        <div className="text-xl sm:text-3xl font-secondary whitespace-nowrap">
          Manage {advisorTerm} Accounts
        </div>
        <UserCountWrapper>
          <Button
            label={`New ${advisorTerm} Account`}
            onClick={createNewUserAccount}
            variant="text"
            leftIcon="plus-circle"
          />
          <Details
            tenantId={tenantId}
            isUserAddedToGroup={isUserAddedToGroup}
            isDisableUserInProgress={isDisableUserInProgress}
          />
        </UserCountWrapper>
        <div className="flex flex-col smd:flex-row smd:justify-between smd:items-end gap-4 mt-4">
          <UserManagementSearchBar
            placeholder={searchInputPlaceholder}
            onSearchClicked={executeSearch}
            onChange={handleSearch}
          />
          {/* SMALL-SCREEN FILTERS */}
          <div className="mdl:hidden grid grid-cols-2 gap-2">
            <SortButton onClick={onSortButtonClicked} />
            <FilterButton onClick={onFilterButtonClicked} />
            {displayedFilterSettingsMenu && (
              <FilterSettingsMenu
                title={filterSettingsMenuTitle}
                onClose={closeFilterModalAndRevertPendingChanges}
                onReset={restoreDefaultSortAndFilters}
                onSettingsApplied={onAppliedClicked}
              >
                {/* Full-screen modal for choosing sort column for mobile users */}
                {displayedFilterSettingsMenu === 'sort' && (
                  <div className="space-y-6">
                    <RadioButtonField
                      options={sortFields}
                      onChange={(v) => setPendingSortField(v)}
                      value={pendingSortField}
                    />
                  </div>
                )}
                {/* Full-screen modal for choosing filters for mobile users */}

                {displayedFilterSettingsMenu === 'filter' && (
                  <>
                    <div className="w-[250px]">
                      <StatusSelect onChange={setPendingStatusFilter} value={pendingStatusFilter} />
                    </div>
                    {showAdvisorFilter && (
                      <div className="w-full max-w-[500px]">
                        <AdvisorSelect
                          advisors={advisors}
                          onChange={setPendingAdvisorFilter}
                          value={pendingAdvisorFilter}
                        />
                      </div>
                    )}
                  </>
                )}
              </FilterSettingsMenu>
            )}
          </div>
          {/* LARGE-SCREEN FILTERS */}
          <div className="hidden mdl:flex justify-end w-full gap-3 items-end">
            {/* filter by status */}
            <div className="mdl:w-6/12 lg:w-3/12 xl:w-2/12">
              <StatusSelect onChange={applyStatusFilter} value={pendingStatusFilter} />
            </div>
            {showAdvisorFilter && (
              <div className="mdl:w-8/12 lg:w-4/12 xl:w-3/12">
                <AdvisorSelect
                  advisors={advisors}
                  onChange={applyAdvisorFilter}
                  value={pendingAdvisorFilter}
                />
              </div>
            )}
            <div className="items-center justify-center pb-4 ml-3 mdl:w-1/12 lg:w-14">
              <Button onClick={() => restoreDefaultSortAndFilters()} label="Reset" variant="text" />
            </div>
          </div>
        </div>

        {/* data-table - displays on larger screens */}
        <section className="hidden mdl:block">
          <div className="w-full min-w-[800px]">
            {/* <DataTable
              pageSize={noOfItemsPerPage}
              columns={tableColumns}
              rows={displayedUserAccounts}
              pagination={true}
              setPageSize={() => {}}
              defaultSort={{ name: SortOrder.Descending }}
              isLoading={haveData && loading}
              loaderType="UserManagementLoader"
              hasMoreData={haveData}
            /> */}
            <DataTableV2
              columns={tableColumns}
              rows={displayedUserAccounts}
              noOfItemsPerPageOptions={noOfItemsPerPageOptions}
              defaultSort={{ name: SortOrder.Descending }}
              isLoading={loading && haveData}
              loaderType="UserManagementLoader"
              currentPage={currentPage}
              noOfItemsPerPage={noOfItemsPerPage}
              totalPages={totalPages}
              moveNextHandler={moveNextHandler}
              movePrevHandler={movePreviousHandler}
              updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
              apiSortHandler={apiDataSortHandler}
            />
          </div>
        </section>
        <section className="mdl:hidden">
          <UserManagementList
            userAccounts={displayedUserAccounts}
            showAdminRightsToggle={userAccountType === ACCOUNT_TYPE.ADVISOR}
            nextPaginatedDataHandler={() => {}}
            pageSize={noOfItemsPerPage}
            noOfItemsPerPageOptions={noOfItemsPerPageOptions}
            currentPage={currentPage}
            noOfItemsPerPage={noOfItemsPerPage}
            totalPages={totalPages}
            moveNextHandler={moveNextHandler}
            movePrevHandler={movePreviousHandler}
            updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
          />
        </section>
      </div>
    </div>
  )
}

export default UserManagementLayout

interface IAdvisorSelectProps {
  advisors: { label: string; value: string }[]
  onChange: (value: string) => any
  value: string
}

const AdvisorSelect: FC<IAdvisorSelectProps> = ({ advisors, onChange, value }) => {
  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const advisorTerm = replaceTerm('advisor', bsoProfile?.terminology)

  return (
    <SelectField
      label={`Filter By ${advisorTerm}`}
      name={'advisor'}
      placeholder="Select"
      options={advisors}
      onChange={(value) => onChange(value)}
      value={value === '' ? FILTER_OPTIONS.ALL.toLowerCase() : value}
      id={ADVISOR_SELECT_TEST_ID}
    />
  )
}

interface IStatusSelectProps {
  onChange: (value: string) => any
  value: string
}

const StatusSelect: FC<IStatusSelectProps> = ({ onChange, value }) => {
  return (
    <SelectField
      label="Filter By Status"
      name="status"
      placeholder="Select"
      options={statusOptions}
      onChange={(value) => onChange(value)}
      value={value === '' ? 'all' : value}
      id={STATUS_SELECT_TEST_ID}
    />
  )
}

const UserManagementSearchBar = ({ onSearchClicked, onChange, placeholder = '' }) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearchClicked()
    }
  }

  return (
    <div className="flex justify-between items-center gap-2 min-w-[335px]">
      <span className="hover:cursor-pointer" onClick={onSearchClicked}>
        <SearchIcon className={`fill-primary cursor-pointer`} />
      </span>
      <InputField
        type={'search'}
        placeholder={placeholder}
        onChange={onChange}
        className="w-full text-sm"
        placeholderFontSize="sm"
        fontSize="base"
        name="search"
        onKeyPress={handleKeyPress}
      />
    </div>
  )
}
