// import { API_TYPE } from 'utils/helper'

type STARTED<S extends string> = `${S}_STARTED`
type FULLFILLED<S extends string> = `${S}_FULLFILLED`
type REJECTED<S extends string> = `${S}_REJECTED`
type RESET<S extends string> = `${S}_RESET`

export const API_TYPE = <S extends string>(
  action: S
): {
  STARTED: STARTED<S>
  FULLFILLED: FULLFILLED<S>
  REJECTED: REJECTED<S>
  RESET: RESET<S>
} => ({
  STARTED: `${action}_STARTED`,
  FULLFILLED: `${action}_FULLFILLED`,
  REJECTED: `${action}_REJECTED`,
  RESET: `${action}_RESET`,
})

export type ReduxAction<T> = (params: T) => {
  type: string
  payload?: T
}

// AUTH
export const LOGIN = API_TYPE('LOGIN')
export const SIGNUP = API_TYPE('SIGNUP')
export const LOGOUT = 'LOGOUT'
export const RESET_STATES = 'RESET_STATES'
export const CHANGE_PASSWORD = API_TYPE('CHANGE_PASSWORD')

// ATTESTATION SUMMARY
export const GET_ATTESTATION_SUMMARY = API_TYPE('GET_ATTESTATION_SUMMARY')

//Common
export const RESET_FORM_DATA = 'RESET_FORM_DATA'
export const SET_FORM_DATA = 'SET_FORM_DATA'
export const UPLOAD_FILE = API_TYPE('UPLOAD_FILE')

//USER
export const CLIENTS_SUMMARY = API_TYPE('CLIENTS_SUMMARY')
export const GET_USER_PROFILE = API_TYPE('GET_USER_PROFILE')
export const UPDATE_USER_PROFILE = API_TYPE('UPDATE_USER_PROFILE')
export const GET_USER_BUSINESS_PROFILE = API_TYPE('GET_USER_BUSINESS_PROFILE')
export const UPDATE_USER_BUSINESS_PROFILE = API_TYPE('UPDATE_USER_BUSINESS_PROFILE')
export const GET_BIG_QUERY = API_TYPE('GET_BIG_QUERY')
export const GET_CHAT_USERS = API_TYPE('GET_CHAT_USERS')
export const CHAT_UNREAD_MESSAGE_COUNT = API_TYPE('CHAT_UNREAD_MESSAGE_COUNT')
export const GET_UNREAD_NOTIFICATIONS = API_TYPE('GET_UNREAD_NOTIFICATIONS')
export const GET_USER_NOTIFICATIONS = API_TYPE('GET_USER_NOTIFICATIONS')
export const UPDATE_USER_NOTIFICATIONS = API_TYPE('UPDATE_USER_NOTIFICATIONS')
export const DELETE_USER_NOTIFICATIONS = API_TYPE('DELETE_USER_NOTIFICATION')
export const PUBLISH_ANNOUNCEMENT_ACTION = API_TYPE('PUBLISH_ANNOUNCEMENT_ACTION')
export const SET_ANNOUNCEMENT_ACTIVE_STEP = 'SET_ANNOUNCEMENT_ACTIVE_STEP'
export const GET_BUSINESS_SUMMARY = API_TYPE('GET_BUSINESS_SUMMARY')
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_PER_PAGE = 'SET_PER_PAGE'
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SORT_VALUE = 'SET_SORT_VALUE'

//Password
export const FORGOT_PASSWORD = API_TYPE('FORGOT_PASSWORD')
export const FORGOT_PASSWORD_SUBMIT = API_TYPE('FORGOT_PASSWORD_SUBMIT')

export const ACTIVESTEP = 'ACTIVESTEP'

//Create Business Account
export const SET_BUSINESS_OWNER_ACTIVE_STEP = 'SET_BUSINESS_OWNER_ACTIVE_STEP'
export const SET_V2_BUSINESS_OWNER_ACTIVE_STEP = 'SET_V2_BUSINESS_OWNER_ACTIVE_STEP'
export const ADD_BUSINESS_ACCOUNT = API_TYPE('ADD_BUSINESS_ACCOUNT')
export const RESET_ACCOUNT_ERROR = 'RESET_ACCOUNT_ERROR'

//Create Advisor Account
export const SET_ADVISOR_ACTIVE_STEP = 'SET_ADVISOR_ACTIVE_STEP'
export const ADD_ADVISOR_ACCOUNT = API_TYPE('ADD_ADVISOR_ACCOUNT')

export const GET_USERS = API_TYPE('GET_USERS')

//Progressbar
export const UPDATE_PROGRESSBAR = 'UPDATE_PROGRESSBAR'
export const TOTAL_STEPS_PROGRESSBAR = 'TOTAL_STEPS_PROGRESSBAR'
export const RESET_PROGRESSBAR = 'RESET_PROGRESSBAR'

//Assessment
export const GET_RECOMMENDED_PLAYS_ASSSESSMENTS = API_TYPE('GET_RECOMMENDED_PLAYS_ASSSESSMENTS')
export const ADD_EDIT_ASSESSMENT = API_TYPE('ADD_EDIT_ASSESSMENT')
export const GET_ASSESSMENT_QUESTION = API_TYPE('GET_ASSESSMENT_QUESTION')
export const SAVE_ASSESSMENT_RESPONSE = API_TYPE('SAVE_ASSESSMENT_RESPONSE')
export const GET_USER_ASSESSMENT_RESPONSE = API_TYPE('GET_USER_ASSESSMENT_RESPONSE')
export const HANDLE_GOAL_ACTION = 'HANDLE_GOAL_ACTION'
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION'
export const INITIALIZE_OWNER_STEPPER = 'INITIALIZE_OWNER_STEPPER'
export const PREVIOUS_QUESTION = 'PREVIOUS_QUESTION'
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT'
export const GET_ASSESSMENT_OPTIONS = API_TYPE('GET_ASSESSMENT_OPTIONS')
export const GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS = API_TYPE(
  'GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS'
)

//Playbook
export const GET_USER_GOAL = API_TYPE('GET_USER_GOAL')
export const UPDATE_USER_GOAL = API_TYPE('UPDATE_USER_GOAL')
export const AUTHORIZE_CONNECTOR = API_TYPE('AUTHORIZE_CONNECTOR')

//Account
export const SHOW_MY_PROFILE = 'SHOW_MY_PROFILE'
export const SHOW_BUSINESS_PROFILE = 'SHOW_BUSINESS_PROFILE'

// BSO
export const GET_BSO_PROFILE = API_TYPE('GET_BSO_PROFILE')
export const SEARCH_INDUSTRY_REPORT = API_TYPE('SEARCH_INDUSTRY_REPORT')

// Assessments
export const UPGRADE_CURRENT_ASSESSMENT_NUMBER = 'UPGRADE_CURRENT_ASSESSMENT_NUMBER'
export const DOWNGRADE_ASSESSMENT_NUMBER = 'DOWNGRADE_ASSESSMENT_NUMBER'
export const ASSESSMENT_BG_COLOR = 'ASSESSMENT_BG_COLOR'

//Goal
export const GET_GOAL_DETAILS = API_TYPE('GET_GOAL_DETAILS')

//Calendar
export const GET_USER_CONNECTORS = API_TYPE('GET_USER_CONNECTORS')
export const GET_USER_CALENDAR_EVENTS = API_TYPE('GET_USER_CALENDAR_EVENTS')
export const GET_OAUTH_URL = API_TYPE('GET_OAUTH_URL')
export const GET_CALENDLY_USER = API_TYPE('GET_CALENDLY_USER')
export const RESET_OAUTH_URL = 'RESET_OAUTH_URL'
export const GET_USER_MEETING = API_TYPE('GET_USER_MEETING')
export const SUBSCRIBE_CALENDLY_WEBHOOK = API_TYPE('SUBSCRIBE_CALENDLY_WEBHOOK')
export const SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK = API_TYPE('SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK')
export const SET_IS_SCHEDULED = 'SET_IS_SCHEDULED'

//Reset Advisor and Business Forms
export const RESET_ADVISOR_BUSINESS_FORMS = 'RESET_ADVISOR_BUSINESS_FORMS'

// Stepper

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const INITIALIZE_STEPPER = 'INITIALIZE_STEPPER'

export const RESET_ERRORS = 'RESET_ERRORS'
//Selected advisor
export const GET_BO_ADVISOR = API_TYPE('GET_BO_ADVISOR')

//Client
export const GET_ADVISORS_DASHBOARD = API_TYPE('GET_ADVISORS_DASHBOARD')
export const GET_CLIENTS = API_TYPE('GET_CLIENTS')
export const GET_TOP_ACTIVE_CLIENTS = API_TYPE('GET_TOP_ACTIVE_CLIENTS')
export const GET_CLIENT_PROFILE = API_TYPE('GET_CLIENT_PROFILE')
export const GET_CLIENT_ACTIVITY_SCORE = API_TYPE('GET_CLIENT_ACTIVITY_SCORE')
export const GET_CLIENT_ACTIVITY_LOGS = API_TYPE('GET_CLIENT_ACTIVITY_LOGS')
export const GET_CLIENT_PROFILE_DETAILS = API_TYPE('GET_CLIENT_PROFILE_DETAILS')
export const GET_CLIENT_ASSESSMENTS = API_TYPE('GET_CLIENT_ASSESSMENTS')
export const GET_CLIENT_ASSESSMENT_LIST = API_TYPE('GET_CLIENT_ASSESSMENT_LIST')
export const GET_CLIENT_CONNECTED_TOOLS = API_TYPE('GET_CLIENT_CONNECTED_TOOLS')

//Get active/top goal or industry icon
export const GET_ASSESSMENT_RESPONSE_BY_QUESTION = API_TYPE('GET_ASSESSMENT_RESPONSE_BY_QUESTION')
export const GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION = API_TYPE(
  'GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION'
)

//Play
export const RESET_PLAY = 'RESET_PLAY'
export const UPDATE_PLAY_STEP = API_TYPE('UPDATE_PLAY_STEP')
export const GET_PLAY = API_TYPE('GET_PLAY')
export const GET_PLAYS = API_TYPE('GET_PLAYS')
export const UPDATE_SETUP_STEP_ID = 'UPDATE_SETUP_STEP_ID'
export const UPDATE_PLAY_STAGE = API_TYPE('UPDATE_PLAY_STAGE')
export const GET_PLAY_ASSESSMENT_RESPONSE = API_TYPE('GET_PLAY_ASSESSMENT_RESPONSE')

//admin user-management
export const GET_BUSINESSES = API_TYPE('GET_BUSINESSES')
export const GET_ADVISORS_ACCOUNT_DETAILS = API_TYPE('GET_ADVISOR_ACCOUNT_DETAILS')
export const ADD_USER_TO_GROUP = API_TYPE('ADD_USER_TO_GROUP')
export const GET_BUSINESSES_UM = API_TYPE('GET_BUSINESSES_UM')
export const GET_ADVISORS_ACCOUNT_DETAILS_UM = API_TYPE('GET_ADVISOR_ACCOUNT_DETAILS_UM')

//admin user-management advisors
export const GET_ADVISORS = API_TYPE('GET_ADVISORS')
export const HANDLE_CYCLR_OAUTH = API_TYPE('HANDLE_CYCLR_OAUTH')
export const HANDLE_COMPLETE_PLAY = API_TYPE('HANDLE_COMPLETE_PLAY')

export const UPDATE_OWNER_PROFILE = API_TYPE('UPDATE_OWNER_PROFILE')
export const UPDATE_ADVISOR_PROFILE = API_TYPE('UPDATE_ADVISOR_PROFILE')
export const GET_USER_PROFILE_AND_ADVISOR = API_TYPE('GET_USER_PROFILE_AND_ADVISOR')
export const GET_BUSINESS_AND_ASSESSMENT_RESPONSE = API_TYPE('GET_BUSINESS_AND_ASSESSMENT_RESPONSE')

//log actions
export const LOG_USER_ACTIVITY = 'LOG_USER_ACTIVITY'

//File
export const SAVE_USER_FILE = API_TYPE('SAVE_USER_FILE')
export const GET_USER_FILES = API_TYPE('GET_USER_FILES')

//enable disable user
export const ENABLE_DISABLE_USER = API_TYPE('ENABLE_DISABLE_USER')

//owner
export const OWNER_ONBOARDING_COMPLETED = 'OWNER_ONBOARDING_COMPLETED'

export const GET_CLIENT_EDIT_PROFILE_DETAIL = API_TYPE('GET_CLIENT_EDIT_PROFILE_DETAIL')
export const UPDATE_CLIENT_PROFILE_DETAIL = API_TYPE('UPDATE_CLIENT_PROFILE_DETAIL')

export const OPEN_CLIENT_DETAIL = 'OPEN_CLIENT_DETAIL'
//Open UserManagement Edit Modal
export const OPEN_USER_MANAGEMENT_EDIT_MODAL = 'OPEN_USER_MANAGEMENT_EDIT_MODAL'

export const SET_PROFILE_FORM = 'SET_PROFILE_FORM'

//PlayBook
export const GET_DASHBOARD_LISTING = API_TYPE('GET_DASHBOARD_LISTING')
export const GET_ASSESSMENT_PLAY_LISTING = API_TYPE('GET_ASSESSMENT_PLAY_LISTING')

//OwnerData
export const GET_SALES_DATA = API_TYPE('GET_SALES_DATA')
export const GET_PRODUCT_DATA = API_TYPE('GET_PRODUCT_DATA')
export const GET_FINANCE_DATA = API_TYPE('GET_FINANCE_DATA')
export const GET_MARKETING_DATA = API_TYPE('GET_MARKETING_DATA')
export const GET_ALL_TOOLS = API_TYPE('GET_ALL_TOOLS')
export const GET_TOOLS_BY_TYPE = API_TYPE('GET_TOOLS_BY_TYPE')
export const TOTAL_UPCOMING_MEETINGS = API_TYPE('TOTAL_UPCOMING_MEETINGS')
export const DE_AUTHENTICATE_TOOLS = API_TYPE('DE_AUTHENTICATE_TOOLS')
export const GET_CASH_BALANCE_DATA = API_TYPE('GET_CASH_BALANCE_DATA')
export const GET_LIQUID_CASH_DATA = API_TYPE('GET_LIQUID_CASH_DATA')
export const GET_CASH_BALANCE_BY_MONTH_DATA = API_TYPE('GET_CASH_BALANCE_BY_MONTH_DATA')
export const GET_PROFIT_AND_LOSS_DATA = API_TYPE('GET_PROFIT_AND_LOSS_DATA')

//Disable play
export const DISABLE_PLAY = 'DISABLE_PLAY'

//Client Meeting Event
export const GET_TACKLE_MEETING_EVENTS = API_TYPE('GET_TACKLE_MEETING_EVENTS')
export const CANCEL_TACKLE_MEETING_EVENT = API_TYPE('CANCEL_TACKLE_MEETING_EVENT')
//Update Comment
export const SHOW_COMMENTS = 'SHOW_COMMENTS'

export const UPDATE_CALENDAR_MEETING = API_TYPE('UPDATE_CALENDAR_MEETING')
export const UPDATE_MY_TOOLS = API_TYPE('UPDATE_MY_TOOLS')
export const GET_MY_TOOLS = API_TYPE('GET_MY_TOOLS')
export const SET_AUTH_TOOLS_SUCCESS = API_TYPE('SET_AUTH_TOOLS_SUCCESS')
export const CANCEL_OUTLOOK_MEETING = API_TYPE('CANCEL_OUTLOOK_MEETING')

export const DELETE_GOOGLE_CALENDAR_EVENT = API_TYPE('DELETE_GOOGLE_CALENDAR_EVENT')
export const UPDATE_GOOGLE_CALENDAR_EVENT = API_TYPE('UPDATE_GOOGLE_CALENDAR_EVENT')
export const GET_CONNECTED_PLAY = API_TYPE('GET_CONNECTED_PLAY')
export const SAVE_PLAY_DATA = API_TYPE('SAVE_PLAY_DATA')
export const CHECK_USER_PROFILE = API_TYPE('CHECK_USER_PROFILE_SET')
export const CHECK_BUSINESS_PROFILE = API_TYPE('CHECK_BUSINESS_PROFILE_SET')
export const RESET_IMAGE_URL = API_TYPE('RESET_IMAGE_URL')
export const INPUT_FIELD_BG_COLOR = 'INPUT_FIELD_BG_COLOR'

export const GET_MEETING_TYPES = API_TYPE('GET_MEETING_TYPES')
export const GET_USER_AVAILABILITY = API_TYPE('GET_USER_AVAILABILITY')
export const GET_USER_AVAILABILITY_HOURS = API_TYPE('GET_USER_AVAILABILITY_HOURS')
export const SET_USER_AVAILABILITY = API_TYPE('SET_USER_AVAILABILITY')
export const UPDATE_USER_AVAILABILITY_HOURS = API_TYPE('UPDATE_USER_AVAILABILITY_HOURS')
export const SET_USER_MEETING = API_TYPE('SET_USER_MEETING')
export const OPEN_SLOTS_MODAL = 'OPEN_SLOTS_MODAL'
export const UPDATE_MEETING_STEPPER = 'UPDATE_MEETING_STEPPER'
export const UPDATE_USER_CALENDAR_EVENT = API_TYPE('UPDATE_USER_CALENDAR_EVENT')
export const CANCEL_USER_CALENDAR_EVENT = API_TYPE('CANCEL_USER_CALENDAR_EVENT')
export const RESEND_USER_INVITE = API_TYPE('RESEND_USER_INVITE')
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL'
export const SET_MONTHLY_ATTESTATION_FORM = API_TYPE('SET_MONTHLY_ATTESTATION_FORM')
export const SAVE_ATTESTATION = API_TYPE('SAVE_ATTESTATION')

export const GET_CLIENT_ATTESTATION_REPORT = API_TYPE('GET_CLIENT_ATTESTATION_REPORT')
export const RESET_CLIENTS = 'RESET_CLIENTS'
export const RESET_ATTESTATION_URL = 'RESET_ATTESTATION_URL'

export const SET_TENANT_ID = 'SET_TENANT_ID'
export const SET_SHOW_SUBSCRIPTION_PROMPT = 'SET_SHOW_SUBSCRIPTION_PROMPT'

export const GET_ADVISOR_PROFILE = API_TYPE('GET_ADVISOR_PROFILE')
export const GET_USER_MEETING_BY_EVENT_ID = API_TYPE('GET_USER_MEETING_BY_EVENT_ID')
export const RESET_ERROR = 'RESET_ERROR'
export const RESET_SAVED_CURRENT_PAGE = 'RESET_SAVED_CURRENT_PAGE'
export const GET_PLAY_RESPONSE = API_TYPE('GET_PLAY_RESPONSE')
export const SAVE_PLAY_RESPONSE = API_TYPE('SAVE_PLAY_RESPONSE')
export const SAVE_USER_PLAY_FILE = API_TYPE('SAVE_USER_PLAY_FILE')
export const UPDATE_BOOKMARK_TOOL = 'UPDATE_BOOKMARK_TOOL'
export const OPEN_MEETING_CONFIRMATION = 'OPEN_MEETING_CONFIRMATION'
export const SET_TOOL_CONNECTION_STATUS = 'SET_TOOL_CONNECTION_STATUS'
export const SET_CURRENT_CALENDAR_DATE = 'SET_CURRENT_CALENDAR_DATE'
export const GET_TOOL_CATEGORIES = 'GET_TOOL_CATEGORIES'
export const GET_SEARCH_TOOLS = API_TYPE('GET_SEARCH_TOOLS')
export const UPDATE_SEARCH_TOOL_CONNECTION_STATUS = 'UPDATE_SEARCH_TOOL_CONNECTION_STATUS'
